@font-face {
  font-family: 'D-din';
  src: url(./fonts/ddin.otf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'D-din';
  src: url(./fonts/D-DIN-Bold.otf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'D-dinExp';
  src: url(./fonts/D-DINExp.otf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'D-dinExp';
  src: url(./fonts/D-DINExp-Bold.otf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'D-dinCondesed';
  src: url(./fonts/D-DINCondensed.otf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'D-dinCondesed';
  src: url(./fonts/D-DINCondensed-Bold.otf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
